import { cn, initArray } from "@/utils/utils";
import React from "react";

type Props = {
	current?: number;
	className?: string;
};
export default function LineLoading({ className, current = 10 }: Props) {
	return (
		<div className={cn(" w-[80px] h-[20px] overflow-hidden ", className)}>
			<div className="  flex  flex-nowrap gap-4  items-center animate-tranX w-fit">
				{initArray(current, "").map((i , index) => {
					return (
					
							<div key={index} className="  rounded-ss-sm w-5 h-[2px] bg-black flex-shrink-0"></div>
						
					);
				})}
			</div>
		</div>
	);
}
