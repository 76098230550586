import { cn } from "@/utils/utils";

export default function Tag({
	className,
	children,
	style,
}: {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}) {
	return (
		<div
			style={style}
			className={cn(
				"w-fit rounded px-[7px]  bg-gray-500 text-white text-[10px] h-[14px] flex justify-center items-center text-center",
				className
			)}>
			{children}
		</div>
	);
}
