"use client";

import { cn } from "@/utils/utils";
import ImageV2 from "../ui/ImageV2";

type Props = {
	className?: string;
};

export default function StarBlackIcon({ className }: Props) {
	return (
		<ImageV2
			className={cn(className)}
			width={10}
			height={10}
			src="/assets/images/starBlackIcon.svg"
			alt="rate"
		/>
	);
}
