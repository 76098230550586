"use client";
import {
  getRateProductClient,
  getTagsProductDetails,
} from "@/lib/api/shop/products";
import { ProductJson, ProductTagJson } from "@/types/Product.type";

import { useQuery } from "@tanstack/react-query";

export default function useRatingProduct(productId: ProductJson["id"]) {
  return useQuery({
    queryKey: ["rating-product", productId],
    queryFn: () => {
      return getRateProductClient(productId);
    },
    staleTime: 60 * 1000 * 5,
  });
}
