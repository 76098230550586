"use client";
import React from "react";
import StarDetail from "./StarDetail";
import { ProductRating as Rating } from "@/types/Product.type";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { cn } from "@/utils/utils";
import { selectDevice } from "@/redux/features/ShopSlice";
import { useAppSelector } from "@/redux/hook";
import useRatingProduct from "@/lib/hooks/useRatingProduct";
import LineLoading from "@/components/loadings/LineLoading";

type Props = {
	// item_type: string;
	defaultRating: Rating;
	item_id: number;
	className?: string;
};
const ProductRating = ({ item_id, className, defaultRating }: Props) => {
	const device = useAppSelector(selectDevice);
	const { t } = useTranslationApi(["count_rate"]);
	const { data, isLoading, error, isSuccess } = useRatingProduct(item_id);
	if (isLoading) {
		return <LineLoading />;
	}
	if (error) {
		return null;
	}

	if (!data) {
		return null;
	}

	const rating = data.data;

	return (
		<div className={cn("flex items-center", className)}>
			{device === "desktop" ? (
				<div className="gap-1 md:inline-flex hidden">
					<StarDetail stars={rating.rate} />
				</div>
			) : (
				<div className="inline-flex gap-1 md:hidden">
					<StarDetail stars={rating.rate} size="mobile" />
				</div>
			)}

			<span
				id="nperp_countRate"
				className="text-sm text-gray-500 ml-2  flex items-center h-full">
				{rating.count_rate}
				<span className="hidden text-gray-500  text-sm md:inline ml-1">
					{t("count_rate")}
				</span>
			</span>
		</div>
	);
};

export default ProductRating;
