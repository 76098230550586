"use client";

import { cn } from "@/utils/utils";
import React, { useMemo } from "react";

import { ProductTagJson, ProductTagTypeJson } from "@/types/Product.type";
import {
	CONFIG_TAGS_COLOR,
	CONFIG_TAGS_GENDER_COLOR,
	CONFIG_TAGS_PRIORITY,
} from "@/common/constants/product";

import Tag from "@/components/Tag";
import { PromotionJson } from "@/types/Promotion.type";

export default function ProductTags({
	tags,
	className,
	itemClassName,
	type = "PRODUCT CARD",
}: {
	tags: ProductTagJson[];
	className?: string;
	itemClassName?: string;
	type?: ProductTagTypeJson;
	promotions?: PromotionJson[];
}) {
	const tagElement = useMemo(() => {
		switch (type) {
			case "PRODUCT CARD":
				return (
					<div className={cn("flex gap-2", className)}>
						{tags.length > 0 &&
							tags
								?.filter(
									(tag) =>
										tag.type === "PRODUCT CARD" &&
										tag.code !== "only" &&
										tag.code !== "sale"
								)
								.sort((a, b) => {
									return (
										CONFIG_TAGS_PRIORITY[a.value] -
										CONFIG_TAGS_PRIORITY[b.value]
									);
								})
								.map((tag, index) => {
									return (
										<Tag
											style={{
												backgroundColor: tag.style?.bg_color,
												color: tag.style?.color,
											}}
											className={cn(
												itemClassName,
												"bg-red-500 text-white flex-shrink-0",
												CONFIG_TAGS_COLOR[tag.code]
											)}
											key={index}>
											{tag.name}
										</Tag>
									);
								})}
					</div>
				);
			case "PRODUCT GENDER":
				return (
					<div className={cn("flex gap-2", className)}>
						{tags
							?.filter((tag) => tag.type === "PRODUCT GENDER")
							.map((tag, index) => {
								return (
									<Tag
										className={cn(
											itemClassName,
											"bg-blue-500 text-gray-500 flex-shrink-0",
											CONFIG_TAGS_GENDER_COLOR[tag.code]
										)}
										key={index}>
										{tag.value}
									</Tag>
								);
							})}
					</div>
				);
			default:
				return <></>;
		}
	}, [type, tags]);

	return <>{tagElement}</>;
}
