"use client";
import { cn } from "@/utils/utils";
import React, { useEffect, useState, useRef } from "react";
import ChevronDownIcon from "./icons/ChevronDownIcon";
import ChevronUpIcon from "./icons/ChevronUpIcon";
import { forwardRef, useImperativeHandle } from "react";
export interface Tab {
	title: string | React.ReactNode;
	content: React.ReactNode;
	className?: string;
	classActive?: string;
	headTitle?: React.ReactNode;
}
type Props = {
	className?: string;
	tabs: Tab[];
	defaultlIndex?: number;
	classNameBody?: string;
	heightViewMore?: number;
	noViewMore?: boolean;
	showHeadTitle?: boolean;
	wrapper?: {
		className?: string;
	};
};

const Tabs = forwardRef(function Tabs(
	{
		tabs,
		defaultlIndex = 0,
		className,
		classNameBody,
		heightViewMore = 500,
		noViewMore,
		showHeadTitle = false,
		wrapper,
	}: Props,
	ref
) {
	const [activeId, setActiveId] = useState<number>(defaultlIndex);
	const [isViewMore, setIsViewMore] = useState<boolean>(false);
	const [isOpenViewMore, setIsOpenViewMore] = useState<boolean>(false);
	const body = useRef<null | HTMLDivElement>(null);
	useEffect(() => {
		setIsOpenViewMore(false);
		setIsViewMore(false);

		if (noViewMore) return;

		body.current?.clientHeight && body.current?.clientHeight > heightViewMore
			? setIsViewMore(true)
			: setIsViewMore(false);
	}, [activeId, tabs]);

	useImperativeHandle(
		ref,
		() => {
			return {
				setTabActive(index: number) {
					setActiveId(index);
				},
			};
		},
		[]
	);

	return (
		<div className={cn("", wrapper?.className)}>
			{showHeadTitle && tabs[activeId].headTitle}

			{tabs.length > 0 && (
				<>
					<ul
						className={cn(
							"flex flex-wrap gap-8 text-center border-b border-gray-200 mb-[24px]",
							className
						)}>
						{tabs.map((tab, index) => {
							return (
								<li
									key={index}
									className={cn(
										`inline-block text-3xl text-gray-400 p-[4px] border-b-[2px] hover:border-gray-500 hover:font-medium border-transparent transition-all cursor-pointer `,
										tab.className,
										activeId === index
											? tab.classActive
												? tab.classActive
												: "text-gray-500 font-medium border-gray-500"
											: ""
									)}
									onClick={() => setActiveId(index)}>
									{/* <p
									className={cn(
										`inline-block text-3xl text-gray-400 p-[4px] border-b-[2px] hover:border-gray-500 hover:font-medium border-transparent transition-all cursor-pointer `,
										tab.className,
										activeId === index
											? tab.classActive
												? tab.classActive
												: "text-gray-500 font-medium border-gray-500"
											: ""
									)}
									onClick={() => setActiveId(index)}
								> */}
									{tab.title}
									{/* </p> */}
								</li>
							);
						})}
					</ul>
					<div
						ref={body}
						className={cn(
							" relative overflow-hidden  bg-white-linear  ",
							isOpenViewMore || noViewMore
								? "max-h-[99999px]"
								: "max-h-[520px]",
							classNameBody
						)}>
						{tabs[activeId].content}

						{isViewMore && !isOpenViewMore && (
							<div
								className=" absolute  bottom-0 left-0 right-0 h-[100px] 
									bg-[linear-gradient(180deg, rgba(255, 255, 255, 0.00) 33%, rgba(255, 255, 255, 0.80) 76.5%, #FFF 100%)]   bg-white-linear"></div>
						)}
					</div>
					{isViewMore && (
						<>
							{isOpenViewMore ? (
								<div className=" mt-6">
									<div
										className="  cursor-pointer  inline-flex items-center "
										onClick={() => setIsOpenViewMore(false)}>
										<p className="  text-lg text-gray-500">Thu gọn </p>
										<ChevronUpIcon className=" w-6 h-6" />
									</div>
								</div>
							) : (
								<div className=" mt-6">
									<div
										className="  cursor-pointer  inline-flex items-center "
										onClick={() => setIsOpenViewMore(true)}>
										<p className="  text-lg text-gray-500">
											Xem đầy đủ thông tin{" "}
										</p>
										<ChevronDownIcon className=" w-6 h-6" />
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
});

export default Tabs;
