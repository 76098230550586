import React, { useMemo } from "react";
import StarIconDetail from "./StarIconDetail";
import { useAppSelector } from "@/redux/hook";
import { selectDevice } from "@/redux/features/ShopSlice";

type Props = {
	stars: number;
	size?: "desktop" | "mobile";
};
const StarDetail = ({ stars }: Props) => {
	const device = useAppSelector(selectDevice);

	const value = useMemo(() => {
		let finalValue = stars;
		const r = stars % 1;
		if (r <= 0) {
			finalValue = stars;
		} else {
			finalValue = Math.floor(stars) + 0.5;
		}

		return finalValue;
	}, [stars]);

	//
	switch (value) {
		case 0:
			return (
				<>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 1:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 1.5:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="halffill"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 2:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 2.5:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="halffill"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 3:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 3.5:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="halffill"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 4:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
		case 4.5:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="halffill"></StarIconDetail>
				</>
			);
		case 5:
			return (
				<>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
					<StarIconDetail size={device} type="filed"></StarIconDetail>
				</>
			);
		default:
			return (
				<>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
					<StarIconDetail size={device} type="line"></StarIconDetail>
				</>
			);
	}
};

export default StarDetail;
