import { Device } from "@/types/Shop.type";
import React, { memo } from "react";

type Props = {
	type?: "filed" | "line" | "halffill";
	size?: Device;
};
const StarIconDetail = ({ type = "line", size = "desktop" }: Props) => {
	const icons = {
		desktop: {
			filed: (
				<span>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.52406 3.55775C7.14939 1.9478 7.46206 1.14282 7.99972 1.14282C8.53739 1.14282 8.85005 1.9478 9.47538 3.55775L9.5045 3.63272C9.85778 4.54227 10.0344 4.99704 10.3944 5.27346C10.7544 5.54987 11.227 5.59358 12.1722 5.681L12.343 5.6968C13.8899 5.83987 14.6634 5.9114 14.8289 6.41958C14.9944 6.92776 14.42 7.46743 13.2712 8.54677L12.8878 8.907C12.3063 9.45338 12.0155 9.72657 11.88 10.0846C11.8547 10.1514 11.8337 10.2198 11.8171 10.2895C11.728 10.6627 11.8131 11.0591 11.9834 11.8517L12.0364 12.0984C12.3494 13.5551 12.5059 14.2835 12.2326 14.5976C12.1306 14.715 11.9979 14.7996 11.8505 14.8411C11.456 14.9523 10.8959 14.481 9.77586 13.5384C9.04037 12.9195 8.67263 12.61 8.25041 12.5404C8.08434 12.513 7.9151 12.513 7.74903 12.5404C7.32681 12.61 6.95907 12.9195 6.22358 13.5384C5.10349 14.481 4.54345 14.9523 4.14898 14.8411C4.00158 14.7996 3.86889 14.715 3.76679 14.5976C3.49358 14.2835 3.65006 13.5551 3.96302 12.0984L4.01603 11.8517C4.18632 11.0591 4.27146 10.6627 4.18238 10.2895C4.16577 10.2198 4.14475 10.1514 4.11947 10.0846C3.98395 9.72657 3.69318 9.45338 3.11165 8.907L2.72825 8.54677C1.57947 7.46743 1.00508 6.92776 1.17058 6.41958C1.33608 5.9114 2.10951 5.83987 3.65639 5.6968L3.82726 5.681C4.77242 5.59358 5.245 5.54987 5.60501 5.27346C5.96502 4.99704 6.14166 4.54227 6.49494 3.63272L6.52406 3.55775Z"
							fill="#D72229"
							stroke="#D72229"
						/>
					</svg>
				</span>
			),
			line: (
				<span>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.52406 3.55775C7.14939 1.9478 7.46206 1.14282 7.99972 1.14282C8.53739 1.14282 8.85005 1.9478 9.47538 3.55775L9.5045 3.63272C9.85778 4.54227 10.0344 4.99704 10.3944 5.27346C10.7544 5.54987 11.227 5.59358 12.1722 5.681L12.343 5.6968C13.8899 5.83987 14.6634 5.9114 14.8289 6.41958C14.9944 6.92776 14.42 7.46743 13.2712 8.54677L12.8878 8.907C12.3063 9.45338 12.0155 9.72657 11.88 10.0846C11.8547 10.1514 11.8337 10.2198 11.8171 10.2895C11.728 10.6627 11.8131 11.0591 11.9834 11.8517L12.0364 12.0984C12.3494 13.5551 12.5059 14.2835 12.2326 14.5976C12.1306 14.715 11.9979 14.7996 11.8505 14.8411C11.456 14.9523 10.8959 14.481 9.77586 13.5384C9.04037 12.9195 8.67263 12.61 8.25041 12.5404C8.08434 12.513 7.9151 12.513 7.74903 12.5404C7.32681 12.61 6.95907 12.9195 6.22358 13.5384C5.10349 14.481 4.54345 14.9523 4.14898 14.8411C4.00158 14.7996 3.86889 14.715 3.76679 14.5976C3.49358 14.2835 3.65006 13.5551 3.96302 12.0984L4.01603 11.8517C4.18632 11.0591 4.27146 10.6627 4.18238 10.2895C4.16577 10.2198 4.14475 10.1514 4.11947 10.0846C3.98395 9.72657 3.69318 9.45338 3.11165 8.907L2.72825 8.54677C1.57947 7.46743 1.00508 6.92776 1.17058 6.41958C1.33608 5.9114 2.10951 5.83987 3.65639 5.6968L3.82726 5.681C4.77242 5.59358 5.245 5.54987 5.60501 5.27346C5.96502 4.99704 6.14166 4.54227 6.49494 3.63272L6.52406 3.55775Z"
							stroke="#D72229"
						/>
					</svg>
				</span>
			),
			halffill: (
				<span>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.38148 3.41493C7.00681 1.80498 7.31948 1 7.85714 1C8.39481 1 8.70747 1.80498 9.33281 3.41493L9.36192 3.4899C9.71521 4.39945 9.89185 4.85422 10.2519 5.13063C10.6119 5.40705 11.0844 5.45076 12.0296 5.53818L12.2005 5.55398C13.7473 5.69705 14.5208 5.76858 14.6863 6.27676C14.8518 6.78494 14.2774 7.32461 13.1286 8.40395L12.7452 8.76418C12.1637 9.31056 11.8729 9.58375 11.7374 9.9418C11.7121 10.0086 11.6911 10.077 11.6745 10.1467C11.5854 10.5199 11.6705 10.9162 11.8408 11.7089L11.8938 11.9556C12.2068 13.4123 12.3633 14.1407 12.0901 14.4548C11.988 14.5722 11.8553 14.6567 11.7079 14.6983C11.3134 14.8094 10.7534 14.3381 9.63328 13.3956C8.89779 12.7767 8.53005 12.4672 8.10783 12.3976C7.94176 12.3702 7.77253 12.3702 7.60645 12.3976C7.18424 12.4672 6.81649 12.7767 6.081 13.3956C4.96091 14.3381 4.40087 14.8094 4.0064 14.6983C3.859 14.6567 3.72631 14.5722 3.62422 14.4548C3.351 14.1407 3.50748 13.4123 3.82044 11.9556L3.87345 11.7089C4.04374 10.9162 4.12889 10.5199 4.0398 10.1467C4.02319 10.077 4.00217 10.0086 3.97689 9.9418C3.84137 9.58375 3.5506 9.31056 2.96907 8.76418L2.58567 8.40395C1.43689 7.32461 0.862502 6.78494 1.028 6.27676C1.1935 5.76858 1.96694 5.69705 3.51381 5.55398L3.68468 5.53818C4.62985 5.45076 5.10243 5.40705 5.46243 5.13063C5.82244 4.85422 5.99908 4.39944 6.35236 3.4899L6.38148 3.41493Z"
							stroke="#D72229"
						/>
						<mask
							id="mask0_2081_1443"
							// style='mask-type:alpha'
							mask-type="alpha"
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="16"
							height="16">
							<path
								d="M6.38148 3.41493C7.00681 1.80498 7.31948 1 7.85714 1C8.39481 1 8.70747 1.80498 9.33281 3.41493L9.36192 3.4899C9.71521 4.39945 9.89185 4.85422 10.2519 5.13063C10.6119 5.40705 11.0844 5.45076 12.0296 5.53818L12.2005 5.55398C13.7473 5.69705 14.5208 5.76858 14.6863 6.27676C14.8518 6.78494 14.2774 7.32461 13.1286 8.40395L12.7452 8.76418C12.1637 9.31056 11.8729 9.58375 11.7374 9.9418C11.7121 10.0086 11.6911 10.077 11.6745 10.1467C11.5854 10.5199 11.6705 10.9162 11.8408 11.7089L11.8938 11.9556C12.2068 13.4123 12.3633 14.1407 12.0901 14.4548C11.988 14.5722 11.8553 14.6567 11.7079 14.6983C11.3134 14.8094 10.7534 14.3381 9.63328 13.3956C8.89779 12.7767 8.53005 12.4672 8.10783 12.3976C7.94176 12.3702 7.77253 12.3702 7.60645 12.3976C7.18424 12.4672 6.81649 12.7767 6.081 13.3956C4.96091 14.3381 4.40087 14.8094 4.0064 14.6983C3.859 14.6567 3.72631 14.5722 3.62422 14.4548C3.351 14.1407 3.50748 13.4123 3.82044 11.9556L3.87345 11.7089C4.04374 10.9162 4.12889 10.5199 4.0398 10.1467C4.02319 10.077 4.00217 10.0086 3.97689 9.9418C3.84137 9.58375 3.5506 9.31056 2.96907 8.76418L2.58567 8.40395C1.43689 7.32461 0.862502 6.78494 1.028 6.27676C1.1935 5.76858 1.96694 5.69705 3.51381 5.55398L3.68468 5.53818C4.62985 5.45076 5.10243 5.40705 5.46243 5.13063C5.82244 4.85422 5.99908 4.39944 6.35236 3.4899L6.38148 3.41493Z"
								fill="#D72229"
								stroke="#D72229"
							/>
						</mask>
						<g mask="url(#mask0_2081_1443)">
							<rect width="8" height="16" fill="#D72229" />
						</g>
					</svg>
				</span>
			),
		},
		mobile: {
			filed: (
				<span>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.52406 3.55775C7.14939 1.9478 7.46206 1.14282 7.99972 1.14282C8.53739 1.14282 8.85005 1.9478 9.47538 3.55775L9.5045 3.63272C9.85778 4.54227 10.0344 4.99704 10.3944 5.27346C10.7544 5.54987 11.227 5.59358 12.1722 5.681L12.343 5.6968C13.8899 5.83987 14.6634 5.9114 14.8289 6.41958C14.9944 6.92776 14.42 7.46743 13.2712 8.54677L12.8878 8.907C12.3063 9.45338 12.0155 9.72657 11.88 10.0846C11.8547 10.1514 11.8337 10.2198 11.8171 10.2895C11.728 10.6627 11.8131 11.0591 11.9834 11.8517L12.0364 12.0984C12.3494 13.5551 12.5059 14.2835 12.2326 14.5976C12.1306 14.715 11.9979 14.7996 11.8505 14.8411C11.456 14.9523 10.8959 14.481 9.77586 13.5384C9.04037 12.9195 8.67263 12.61 8.25041 12.5404C8.08434 12.513 7.9151 12.513 7.74903 12.5404C7.32681 12.61 6.95907 12.9195 6.22358 13.5384C5.10349 14.481 4.54345 14.9523 4.14898 14.8411C4.00158 14.7996 3.86889 14.715 3.76679 14.5976C3.49358 14.2835 3.65006 13.5551 3.96302 12.0984L4.01603 11.8517C4.18632 11.0591 4.27146 10.6627 4.18238 10.2895C4.16577 10.2198 4.14475 10.1514 4.11947 10.0846C3.98395 9.72657 3.69318 9.45338 3.11165 8.907L2.72825 8.54677C1.57947 7.46743 1.00508 6.92776 1.17058 6.41958C1.33608 5.9114 2.10951 5.83987 3.65639 5.6968L3.82726 5.681C4.77242 5.59358 5.245 5.54987 5.60501 5.27346C5.96502 4.99704 6.14166 4.54227 6.49494 3.63272L6.52406 3.55775Z"
							fill="#D72229"
							stroke="#D72229"
						/>
					</svg>
				</span>
			),
			line: (
				<span>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.52406 3.55775C7.14939 1.9478 7.46206 1.14282 7.99972 1.14282C8.53739 1.14282 8.85005 1.9478 9.47538 3.55775L9.5045 3.63272C9.85778 4.54227 10.0344 4.99704 10.3944 5.27346C10.7544 5.54987 11.227 5.59358 12.1722 5.681L12.343 5.6968C13.8899 5.83987 14.6634 5.9114 14.8289 6.41958C14.9944 6.92776 14.42 7.46743 13.2712 8.54677L12.8878 8.907C12.3063 9.45338 12.0155 9.72657 11.88 10.0846C11.8547 10.1514 11.8337 10.2198 11.8171 10.2895C11.728 10.6627 11.8131 11.0591 11.9834 11.8517L12.0364 12.0984C12.3494 13.5551 12.5059 14.2835 12.2326 14.5976C12.1306 14.715 11.9979 14.7996 11.8505 14.8411C11.456 14.9523 10.8959 14.481 9.77586 13.5384C9.04037 12.9195 8.67263 12.61 8.25041 12.5404C8.08434 12.513 7.9151 12.513 7.74903 12.5404C7.32681 12.61 6.95907 12.9195 6.22358 13.5384C5.10349 14.481 4.54345 14.9523 4.14898 14.8411C4.00158 14.7996 3.86889 14.715 3.76679 14.5976C3.49358 14.2835 3.65006 13.5551 3.96302 12.0984L4.01603 11.8517C4.18632 11.0591 4.27146 10.6627 4.18238 10.2895C4.16577 10.2198 4.14475 10.1514 4.11947 10.0846C3.98395 9.72657 3.69318 9.45338 3.11165 8.907L2.72825 8.54677C1.57947 7.46743 1.00508 6.92776 1.17058 6.41958C1.33608 5.9114 2.10951 5.83987 3.65639 5.6968L3.82726 5.681C4.77242 5.59358 5.245 5.54987 5.60501 5.27346C5.96502 4.99704 6.14166 4.54227 6.49494 3.63272L6.52406 3.55775Z"
							stroke="#D72229"
						/>
					</svg>
				</span>
			),
			halffill: (
				<span>
					{/* <svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.38148 3.41493C7.00681 1.80498 7.31948 1 7.85714 1C8.39481 1 8.70747 1.80498 9.33281 3.41493L9.36192 3.4899C9.71521 4.39945 9.89185 4.85422 10.2519 5.13063C10.6119 5.40705 11.0844 5.45076 12.0296 5.53818L12.2005 5.55398C13.7473 5.69705 14.5208 5.76858 14.6863 6.27676C14.8518 6.78494 14.2774 7.32461 13.1286 8.40395L12.7452 8.76418C12.1637 9.31056 11.8729 9.58375 11.7374 9.9418C11.7121 10.0086 11.6911 10.077 11.6745 10.1467C11.5854 10.5199 11.6705 10.9162 11.8408 11.7089L11.8938 11.9556C12.2068 13.4123 12.3633 14.1407 12.0901 14.4548C11.988 14.5722 11.8553 14.6567 11.7079 14.6983C11.3134 14.8094 10.7534 14.3381 9.63328 13.3956C8.89779 12.7767 8.53005 12.4672 8.10783 12.3976C7.94176 12.3702 7.77253 12.3702 7.60645 12.3976C7.18424 12.4672 6.81649 12.7767 6.081 13.3956C4.96091 14.3381 4.40087 14.8094 4.0064 14.6983C3.859 14.6567 3.72631 14.5722 3.62422 14.4548C3.351 14.1407 3.50748 13.4123 3.82044 11.9556L3.87345 11.7089C4.04374 10.9162 4.12889 10.5199 4.0398 10.1467C4.02319 10.077 4.00217 10.0086 3.97689 9.9418C3.84137 9.58375 3.5506 9.31056 2.96907 8.76418L2.58567 8.40395C1.43689 7.32461 0.862502 6.78494 1.028 6.27676C1.1935 5.76858 1.96694 5.69705 3.51381 5.55398L3.68468 5.53818C4.62985 5.45076 5.10243 5.40705 5.46243 5.13063C5.82244 4.85422 5.99908 4.39944 6.35236 3.4899L6.38148 3.41493Z"
							stroke="#D72229"
						/>
						<mask
							id="mask0_2081_1443"
							// style='mask-type:alpha'
							mask-type="alpha"
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="16"
							height="16">
							<path
								d="M6.38148 3.41493C7.00681 1.80498 7.31948 1 7.85714 1C8.39481 1 8.70747 1.80498 9.33281 3.41493L9.36192 3.4899C9.71521 4.39945 9.89185 4.85422 10.2519 5.13063C10.6119 5.40705 11.0844 5.45076 12.0296 5.53818L12.2005 5.55398C13.7473 5.69705 14.5208 5.76858 14.6863 6.27676C14.8518 6.78494 14.2774 7.32461 13.1286 8.40395L12.7452 8.76418C12.1637 9.31056 11.8729 9.58375 11.7374 9.9418C11.7121 10.0086 11.6911 10.077 11.6745 10.1467C11.5854 10.5199 11.6705 10.9162 11.8408 11.7089L11.8938 11.9556C12.2068 13.4123 12.3633 14.1407 12.0901 14.4548C11.988 14.5722 11.8553 14.6567 11.7079 14.6983C11.3134 14.8094 10.7534 14.3381 9.63328 13.3956C8.89779 12.7767 8.53005 12.4672 8.10783 12.3976C7.94176 12.3702 7.77253 12.3702 7.60645 12.3976C7.18424 12.4672 6.81649 12.7767 6.081 13.3956C4.96091 14.3381 4.40087 14.8094 4.0064 14.6983C3.859 14.6567 3.72631 14.5722 3.62422 14.4548C3.351 14.1407 3.50748 13.4123 3.82044 11.9556L3.87345 11.7089C4.04374 10.9162 4.12889 10.5199 4.0398 10.1467C4.02319 10.077 4.00217 10.0086 3.97689 9.9418C3.84137 9.58375 3.5506 9.31056 2.96907 8.76418L2.58567 8.40395C1.43689 7.32461 0.862502 6.78494 1.028 6.27676C1.1935 5.76858 1.96694 5.69705 3.51381 5.55398L3.68468 5.53818C4.62985 5.45076 5.10243 5.40705 5.46243 5.13063C5.82244 4.85422 5.99908 4.39944 6.35236 3.4899L6.38148 3.41493Z"
								fill="#D72229"
								stroke="#D72229"
							/>
						</mask>
						<g mask="url(#mask0_2081_1443)">
							<rect width="8" height="16" fill="#D72229" />
						</g>
					</svg> */}
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.38148 3.41493C7.00681 1.80498 7.31948 1 7.85714 1C8.39481 1 8.70747 1.80498 9.33281 3.41493L9.36192 3.4899C9.71521 4.39945 9.89185 4.85422 10.2519 5.13063C10.6119 5.40705 11.0844 5.45076 12.0296 5.53818L12.2005 5.55398C13.7473 5.69705 14.5208 5.76858 14.6863 6.27676C14.8518 6.78494 14.2774 7.32461 13.1286 8.40395L12.7452 8.76418C12.1637 9.31056 11.8729 9.58375 11.7374 9.9418C11.7121 10.0086 11.6911 10.077 11.6745 10.1467C11.5854 10.5199 11.6705 10.9162 11.8408 11.7089L11.8938 11.9556C12.2068 13.4123 12.3633 14.1407 12.0901 14.4548C11.988 14.5722 11.8553 14.6567 11.7079 14.6983C11.3134 14.8094 10.7534 14.3381 9.63328 13.3956C8.89779 12.7767 8.53005 12.4672 8.10783 12.3976C7.94176 12.3702 7.77253 12.3702 7.60645 12.3976C7.18424 12.4672 6.81649 12.7767 6.081 13.3956C4.96091 14.3381 4.40087 14.8094 4.0064 14.6983C3.859 14.6567 3.72631 14.5722 3.62422 14.4548C3.351 14.1407 3.50748 13.4123 3.82044 11.9556L3.87345 11.7089C4.04374 10.9162 4.12889 10.5199 4.0398 10.1467C4.02319 10.077 4.00217 10.0086 3.97689 9.9418C3.84137 9.58375 3.5506 9.31056 2.96907 8.76418L2.58567 8.40395C1.43689 7.32461 0.862502 6.78494 1.028 6.27676C1.1935 5.76858 1.96694 5.69705 3.51381 5.55398L3.68468 5.53818C4.62985 5.45076 5.10243 5.40705 5.46243 5.13063C5.82244 4.85422 5.99908 4.39944 6.35236 3.4899L6.38148 3.41493Z" stroke="#D72229"></path>
  <mask id="mask0_2081_1443" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
    <path d="M6.38148 3.41493C7.00681 1.80498 7.31948 1 7.85714 1C8.39481 1 8.70747 1.80498 9.33281 3.41493L9.36192 3.4899C9.71521 4.39945 9.89185 4.85422 10.2519 5.13063C10.6119 5.40705 11.0844 5.45076 12.0296 5.53818L12.2005 5.55398C13.7473 5.69705 14.5208 5.76858 14.6863 6.27676C14.8518 6.78494 14.2774 7.32461 13.1286 8.40395L12.7452 8.76418C12.1637 9.31056 11.8729 9.58375 11.7374 9.9418C11.7121 10.0086 11.6911 10.077 11.6745 10.1467C11.5854 10.5199 11.6705 10.9162 11.8408 11.7089L11.8938 11.9556C12.2068 13.4123 12.3633 14.1407 12.0901 14.4548C11.988 14.5722 11.8553 14.6567 11.7079 14.6983C11.3134 14.8094 10.7534 14.3381 9.63328 13.3956C8.89779 12.7767 8.53005 12.4672 8.10783 12.3976C7.94176 12.3702 7.77253 12.3702 7.60645 12.3976C7.18424 12.4672 6.81649 12.7767 6.081 13.3956C4.96091 14.3381 4.40087 14.8094 4.0064 14.6983C3.859 14.6567 3.72631 14.5722 3.62422 14.4548C3.351 14.1407 3.50748 13.4123 3.82044 11.9556L3.87345 11.7089C4.04374 10.9162 4.12889 10.5199 4.0398 10.1467C4.02319 10.077 4.00217 10.0086 3.97689 9.9418C3.84137 9.58375 3.5506 9.31056 2.96907 8.76418L2.58567 8.40395C1.43689 7.32461 0.862502 6.78494 1.028 6.27676C1.1935 5.76858 1.96694 5.69705 3.51381 5.55398L3.68468 5.53818C4.62985 5.45076 5.10243 5.40705 5.46243 5.13063C5.82244 4.85422 5.99908 4.39944 6.35236 3.4899L6.38148 3.41493Z" fill="#D72229" stroke="#D72229"></path>
  </mask>
  <g mask="url(#mask0_2081_1443)">
    <rect width="8" height="16" fill="#D72229"></rect>
  </g>
</svg>
				</span>
			),
		},
	};

	let com = null;
	switch (size) {
		default:
			com = icons[size][type];
			break;
	}
	return <>{com}</>;
};

export default StarIconDetail;
